import 'url-search-params-polyfill'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { httpClient, coApplicantHttpClient } from '@/utils/http-client'
import { getUnderwritingMetadata, isUnderwritingMetadataReady } from '@/utils/uwMetaData'
import store from '@/store'
import { OpenReplay } from 'aven_shared/src/services/openReplay'

const openReplay = new OpenReplay(process.env.VUE_APP_OPENREPLAY_PROJECT_KEY)

export const featureFlags = [sessionStorageKey.trustsFeatureFlag]

export const setLocalMetadata = () => {
    if (document.referrer) {
        const parser = new URL(document.referrer)
        window.logEvent('system_aven_init', { referrer: document.referrer, referring_domain: parser.hostname })
    }

    // Parsing invite code from query params for tryaven.com redirects
    // https://tryaven.com/BLAH1234 -> https://aven.com/?referrer=tryaven%2FBLAH1234 where %2F is url encoding of a forward slash '/'
    const query = new URLSearchParams(window.location.search)
    logger.info(`query ${query}; referrer ${document.referrer}; location href ${window.location.href}`)

    if (query.has('referrer') || query.has('invitecode') || query.has('code')) {
        const inviteCode = query.get('referrer')?.split('/')?.[1] || query.get('invitecode') || query.get('code')
        if (inviteCode) {
            appSessionStorage.setItem(sessionStorageKey.inviteCode, inviteCode)
        }
    } else if (query.has('pifinvitecode')) {
        const pifInviteCode = query.get('pifinvitecode') as string
        appSessionStorage.setItem(sessionStorageKey.pifInviteCode, pifInviteCode)
    }

    featureFlags.forEach((flag) => {
        if (query.get(flag)) {
            appSessionStorage.setItem(flag, 'True')
        }
    })
}

export const getUserTraits = (): Record<string, string> => {
    const autoApplicationId = store.state.persistedStore.autoApplicationId
    const autoApplicantId = store.state.persistedStore.autoApplicantId
    const experimentName = isUnderwritingMetadataReady() ? getUnderwritingMetadata()?.ExperimentName : 'prerender'
    const abTests = appSessionStorage.getItem(sessionStorageKey.experimentsOverrides)
    const traits: any = {}

    if (autoApplicationId) {
        traits.autoApplicationId = String(autoApplicationId)
    }

    if (autoApplicantId) {
        traits.autoApplicantId = String(autoApplicantId)
    }

    if (experimentName) {
        traits.experimentName = experimentName
    }

    if (abTests) {
        traits.abTests = abTests
    }

    return traits
}

export const initializeSessionRecording = () => {
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
    if (sessionId) {
        const userTraits = getUserTraits()
        if (process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
            logger.info(`init openReplay session with traits: ${JSON.stringify(userTraits)}`)

            // Initialize OpenReplay and get the session recording URL
            openReplay
                .init(sessionId, userTraits, [httpClient, coApplicantHttpClient], httpClient)
                .then((sessionRecordingUrl) => {
                    // Submit the session recording URL to the backend
                    if (sessionRecordingUrl) {
                        appSessionStorage.setItem(sessionStorageKey.sessionRecordingInitialized, 'true')
                        appSessionStorage.setItem(sessionStorageKey.sessionRecordingUrl, sessionRecordingUrl)
                    } else {
                        logger.error(`Could not get session recording URL for openReplay`)
                    }
                })
                .catch((error) => {
                    logger.error(`Failed to initialize openReplay`, error)
                })
        }
    } else {
        logger.error(`Could not initialize session recording with no sessionId!`)
    }
}

export const setSessionMetadata = (metadata: Record<string, string>) => {
    if (openReplay.openReplayTracker) {
        openReplay.trySetMetadata(metadata)
    }
}
